<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div v-if="isLoaded" class="card card-custom gutter-b">
          <div class="card-header border-0 py-5">
            <div class="card-title align-items-center">
              <h3 class="card-label">Today Devices Revenue</h3>
            </div>
            <div class="card-toolbar">
              <button
                @click.prevent="exportAsExcel"
                class="btn btn-light-primary font-weight-bold px-10"
              >
                Export .xlsx
              </button>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr class="text-uppercase">
                        <th class="text-left pl-6">
                          <!-- <span
                            @click="orderDevicesByName"
                            class="cursor-pointer"
                          > -->
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': nameOrder.isActive }"
                          >
                            Name</span
                          >
                          <!-- <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              /> -->
                          <!-- </span> -->
                          <!-- </span> -->
                        </th>
                        <th>{{ $t('GENERAL.LOCATION') }}</th>
                        <th v-if="currentUserCompanyModule[0] === 'gaming'">
                          <!-- <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          > -->
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': coinOrder.isActive }"
                          >
                            Coin</span
                          >
                          <!-- <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': coinOrder.isActive,
                              }"
                            > -->
                          <!-- <inline-svg
                                v-if="coinOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              /> -->
                          <!-- </span> -->
                          <!-- </span> -->
                        </th>
                        <th v-if="currentUserCompanyModule[0] === 'gaming'">
                          <!-- <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          > -->
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': smsOrder.isActive }"
                          >
                            SMS</span
                          >
                          <!-- <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': smsOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="smsOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span> -->
                        </th>
                        <th v-if="currentUserCompanyModule[0] === 'gaming'">
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': smsOrder.isActive }"
                          >
                            Card</span
                          >
                        </th>
                        <th v-if="currentUserCompanyModule[0] === 'gaming'">
                          <!-- <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          > -->
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': loyaltyOrder.isActive }"
                          >
                            Loyalty</span
                          >
                          <!-- <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': loyaltyOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="loyaltyOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span> -->
                        </th>
                        <th>
                          <!-- <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          > -->
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': totalOrder.isActive }"
                          >
                            Total</span
                          >
                          <!-- <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': totalOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="totalOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span> -->
                          <!-- </span> -->
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(device, key) in devices" :key="device.id">
                        <td :class="{ 'border-top-0': key === 0 }" class="pl-6">
                          <span
                            class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                            >{{ device.name }}</span
                          >
                          <div>
                            <span
                              class="label label-sm label-light-primary label-inline mr-2"
                              >{{ device.tags }}</span
                            >
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span class="font-weight-bolder">{{
                            device.location_name
                          }}</span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="device.pay_coin > 0"
                            >{{ device.pay_coin }}
                          </span>
                          <span v-else>0€</span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="device.pay_sms > 0"
                            >{{ device.pay_sms }}
                          </span>
                          <span v-else>0 </span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="device.pay_card > 0"
                            >{{ device.pay_card }}
                          </span>
                          <span v-else>0 </span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="device.pay_rfcard > 0"
                            >{{ device.pay_rfcard }}
                          </span>
                          <span v-else>0 </span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          {{ device.total }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer py-6">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">Osnovica: </span>
              <span>{{ base }} <span> </span></span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">PDV: (25%)</span>
              <span>{{ tax }} <span> </span></span>
            </div>
            <div
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="d-flex justify-content-between mt-2"
            >
              <span class="font-size-lg font-weight-bolder text-dark"
                >Coin:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ coinTotal }} <span> </span
              ></span>
            </div>
            <div
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="d-flex justify-content-between"
            >
              <span class="font-size-lg font-weight-bolder text-dark"
                >Card:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ smsTotal }} <span> </span
              ></span>
            </div>
            <div
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="d-flex justify-content-between"
            >
              <span class="font-size-lg font-weight-bolder text-dark"
                >CARD:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ cardTotal }} <span> </span
              ></span>
            </div>
            <div
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="d-flex justify-content-between"
            >
              <span class="font-size-lg font-weight-bolder text-dark"
                >Loyalty:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ loyaltyTotal }} <span> </span
              ></span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Ukupno:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ total }} <span> </span
              ></span>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 text-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import axios from 'axios'
import ApiService from '@/core/services/api.service'
import * as XLSX from 'xlsx'

export default {
  name: 'TodayRevenue',
  data() {
    return {
      devices: [],
      currentTime: new Date(),
      base: 0,
      tax: 0,
      total: 0,
      coinTotal: 0,
      smsTotal: 0,
      cardTotal: 0,
      loyaltyTotal: 0,
      isLoaded: false,
      activeTableView: 'simple',
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
      locationOrder: {
        query: 'asc',
        isActive: false,
      },
      transactionsNumberOrder: {
        query: 'asc',
        isActive: false,
      },
      lastCheckOrder: {
        query: 'asc',
        isActive: false,
      },
      coinOrder: {
        query: 'asc',
        isActive: false,
      },
      smsOrder: {
        query: 'asc',
        isActive: false,
      },
      loyaltyOrder: {
        query: 'asc',
        isActive: false,
      },
      totalOrder: {
        query: 'asc',
        isActive: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Today Devices Revenue', route: '/today-revenue/list' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_ADMIN'
    ) {
      this.getDevices(
        `${process.env.VUE_APP_ROUTE_API_URL}${this.currentUserCompany}/device-daily-revenue`
      ).then(({ data }) => {
        this.devices = data
        this.devices.forEach((device) => {
          this.coinTotal += Number(device.pay_coin)
          this.smsTotal += Number(device.pay_sms)
          this.cardTotal += Number(device.pay_card)
          this.loyaltyTotal += Number(device.pay_rfcard)
          this.base += Number(device.total) * 0.8
          this.tax += Number(device.total) - device.total * 0.8
          this.total += Number(device.total)
        })
        this.base = this.currencyFormat(this.base)
        this.tax = this.currencyFormat(this.tax)
        this.total = this.currencyFormat(this.total)
        this.coinTotal = this.currencyFormat(this.coinTotal)
        this.smsTotal = this.currencyFormat(this.smsTotal)
        this.loyaltyTotal = this.currencyFormat(this.loyaltyTotal)
        this.cardTotal = this.currencyFormat(this.cardTotal)

        this.isLoaded = true
      })
    }
  },
  methods: {
    async getDevices(endpoint) {
      try {
        return await axios.get(endpoint)
      } catch (e) {
        throw new Error(e)
      }
    },
    currencyFormat(num) {
      return num
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    diffInMinutes(dateLeft, dateRight) {
      if (dateLeft) {
        return differenceInMinutes(new Date(dateLeft), new Date(dateRight))
      }
    },
    orderDevicesByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[name]=${this.nameOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    orderDevicesByLocation() {
      this.locationOrder.isActive = true

      if (this.locationOrder.query === 'asc') {
        this.devices.sort((a, b) => a.locationMeta.title - b.locationMeta.title)
        this.locationOrder.query = 'desc'
      } else {
        this.devices.sort((a, b) => b.locationMeta.title - a.locationMeta.title)
        this.locationOrder.query = 'asc'
      }
    },
    orderDevicesByTransactionsNumber() {
      this.transactionsNumberOrder.isActive = true

      if (this.transactionsNumberOrder.query === 'asc')
        this.transactionsNumberOrder.query = 'desc'
      else this.transactionsNumberOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[total]=${this.transactionsNumberOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    orderDevicesByTotal() {
      this.totalOrder.isActive = true

      if (this.totalOrder.query === 'asc') this.totalOrder.query = 'desc'
      else this.totalOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[total]=${this.totalOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    orderDevicesByLastCheck() {
      this.lastCheckOrder.isActive = true

      if (this.lastCheckOrder.query === 'asc')
        this.lastCheckOrder.query = 'desc'
      else this.lastCheckOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[updatedAt]=${this.nameOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    exportAsExcel() {
      const ws = XLSX.utils.json_to_sheet(this.devices)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, 'daily-revenue.xlsx')
    },
  },
}
</script>

<style>
.vc-container {
  font-family: 'Poppins', sans-serif !important;
}
</style>
